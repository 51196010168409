.container{
  background-color: transparent;
}

.login-text-field.menu-search input {
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  padding-right: 28px;
  padding-left: 28px;
  border-style: none;
  border-radius: 20px;
  background-color: #f1f1f1;
  font-size: 12px;
}

.ais-SearchBox-form{
  margin-top: -8px;
}

.text-field input {
  height: 48px;
  border-radius: 9px;
  border: none;
}

.creator-overlay-container{
  display: flex;
  position: fixed;
}

.overlay-container{
  width: 100%;
}

.react-portal{
  overflow: scroll;
  overflow-x: hidden;
}

.inline-flex{
  display: inline-flex;
}


.hidden{
  display: none!important;
}

.object-container.ui.segment {
  background: transparent;
  box-shadow: none;
  margin: 1%;
  padding: 0px!important;
  border: none;
}

textarea, select, input, button { outline: none; }


.aspect-ratio {
  position: absolute;
  left: 0;
  top: 0;
  top: 0;
}

.aspect-portrait, aspect-landscape{
  display: flex;
}

.aspect-ratio{
  pointer-events: none;
}

.image-element-class img:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.my-masonry-grid_column > div{
  cursor: pointer;
  background-color: transparent!important;
}


.margin-bottom-20{
  margin-bottom: 20px!important;
}

.slider-transform{
  height: auto;
  background-color: transparent;
}

/* SLIDER  */
.rc-slider-track{
  background-color: #e9e9e9!important;
}
.rc-slider-handle{
  border: solid 2px #7e7e7e!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.input-unit input:focus {
  outline: none;
  focus:none;
  outline-width: 0;
}



.hideanimation-enter {
  opacity: 0;
}
.hideanimation-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.hideanimation-exit {
  opacity: 1;
}
.hideanimation-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

/* COLOR PICKER */
.colorPickerContainer{
  padding: 25px 25px;
}

.chrome-picker {
  width:100%!important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.13) 0px 4px 8px!important;
}

.circle-picker{
  width: 100% !important
}

.placeholder .header{
  min-height: auto!important;
  padding: 0!important;
}



.objectimagecontainer img{
  border-radius: 5px;
}


.panelbody{
  height: calc(100% - 56px - 68px);
  overflow-y: auto;
  padding: 0 3px;
  padding-right: 10px;
  margin-right: -10px;
  padding-bottom: 40px;
}

 .panelbody-body, .tab-container-panel{
  padding-left: 20px;
  padding-right: 20px;
}
.viewer-side-menu-container{
  height: 100%;
  overflow: hidden!important;

}

.viewer-side-menu-container-2{
  height: calc(100vh - 40px);
  overflow: hidden;
}

.imageSpacerVertical{
  margin-bottom: 5px;
}

.chrome-picker {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.13);
}

.cam-viewer-container{
  position: absolute;
  top:0;
  pointer-events: none;
}

.grid-button, .camer-button-container{
  pointer-events: initial!important;
}

.masonry-grid-image, .image-element-hover{
  border-radius: 5px;
}


.square-image-container {
  width: 100%;
}

.square-image-container {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.backend-other-status-container{
  position: absolute;
}

.backend-photo-overlay-container{
  display: flex!important;
  width: calc( 100% - 250px );
  left: 250px!important;
}

.user-hover-menu{
  display: none;
}

.user-backend-container:hover .user-hover-menu, .user-hover-menu:hover, .user-image-char:hover .user-hover-menu, .hover-show-user-menu:hover .user-hover-menu {
  display: block!important;
}

.hover-show-user-menu {
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.error-container{
  margin-top: 10px;
}

.div-block-12.list{
  padding-bottom: 0px!important;
}

.ui.green-zerolens .bar{
  background-color: #5cb85c;
}


.creator-overlay-container, .my-masonry-grid{
  user-select: none;
}

.previewImgRender{
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  opacity: 0.5;
}

.text-block-29{
  z-index: 2;
}

.transparentBg{
  background-color: transparent!important;
  box-shadow: none!important;
  border: none!important;
}

.unity-viewer-container{
  overflow: hidden;
}

.refill-credits-button{
  color: #fff!important;
}

.docsContainer{
  border: none!important;
}

.success-color{
  background-color: #0ea432;
}

.whiteLink, .whiteLink a{
  color: white!important;
}

.footer-more{
  font-size: 14px;
}

.help-menu-item:hover, .help-menu-item:hover a{
  color:white!important;
  opacity: 1;
}

.save-area-cam-viewer.darken{
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: background-color 250ms linear;
  -ms-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
}

.login-text-field.menu-search.w-input.side-bar{
  /*padding-left: 7.5px;*/
  /*padding-right: 7.5px;*/
  font-size: 10px;
}

ul.ais-Menu-list li.ais-Menu-item div li.nav-item,
li.nav-item, li.nav-item a.nav-link {
  display:inline !important;
}

.modifier-image{
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 17.5px;
  opacity: 0.75;
}

.watermark-overlay{
  opacity: 0;
  background-image: url("../images/watermark.png");
}

.watermark-overlay.show{
  opacity: 1;
  -webkit-transition: opacity 250ms linear;
  -ms-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}

.fullscreen-loader{
  width: 100vw;
  height: 100vh;
}

.fullscreen-loader .ui.segment {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.absolute{
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
}

.credits-viewer-container{
  z-index: 200;
}

.blur {
  filter: blur(3px);
}

.unlock-container{
  display:none!important;
}

.backend-photo-card:hover .unlock-container{
  display: flex!important;
}

.edit-render{
  z-index:2!important;
}
.selected-container{
  z-index:3!important;
}

.div-block-40{
  margin-bottom: 0!important;
}

.credits-viewer-container-copy{
  position: fixed!important;
  position: fixed;
  left: 250px!important;
  top: 0%;
  width: calc( 100% - 250px );
}

.credits-information-text-half{
  flex: auto!important;
}

.single-material-container:hover .material-name{
  opacity: 1;
}



.scrollable-container{
  overflow-y: auto!important;
  overflow-x: hidden!important;
  height: calc(90vh - 70px )!important;
  padding-bottom: 45px;
}
.addobjectscategories{
  max-width: 203px;
  overflow-x: auto!important;
  overflow-y: hidden!important;
  -ms-overflow-style: none!important;
  padding-bottom: 0!important;
  height: 27px!important;
  margin-top: 0!important;
}

.addobjectscategories::-webkit-scrollbar {
  display: none;
}

.object-panel-viewer{
  top: calc( 5vh + 0px) !important;
  right: 307px!important;
}

.p-colorpicker-preview{
  width: 22px!important;
  height: 22px!important;
  border-radius: 100px!important;
  border: none!important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.16) !important;
  cursor: pointer;
}

.p-colorpicker-panel{
  left: -232px!important;
  top: 27px!important;
  width: 266px!important;
}

.p-colorpicker-panel .p-colorpicker-hue{
  left: 240px!important;
}

.p-colorpicker-color-selector, .p-colorpicker-color{
  width: 222px!important;
}


.zl-tooltip{
  background-color: #404040;
  padding: 7.5px;
  position: absolute;
  top: 27px;
  z-index: 3;
  left:-3px;
  border-radius: 3px;
  color: #d9d9d9!important;
}


/*.zl-tooltip.light .css-x1r72b-Slider, .light .css-x1r72b-Slider, .light .zl-tooltip, .light .css-1wllaw9-ColorPicker div:only-of-type, .light .colorSwatchPreview{*/
  /*border: 1px solid #bbbec5;*/
/*}*/

/*.zl-tooltip.light{*/
  /*background-color: #f2f5fb;*/
  /*padding: 7.5px;*/
  /*position: absolute;*/
  /*top: 27px;*/
  /*z-index: 3;*/
  /*left:-3px;*/
  /*border-radius: 3px;*/
  /*color: #d9d9d9!important;*/
  /*border: 1px solid #bbbec5;*/
/*}*/

.zl-tooltip-hit-color{
  width: 22px;
  height: 22px;
  position:relative;
}

.completeOverlay{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  border-radius: 5px;
  z-index: 2;
}

.inputFieldNumber{
  padding: 2px!important;
  line-height: normal!important;
  height: 20px;
  width: 30px;
  font-size: 11px;
  background-color: #2b2b2b;
  border: 1px solid #212121;
  color: #d9d9d9;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 4px!important;
}

.css-x1r72b-Slider, .zl-tooltip, .css-1wllaw9-ColorPicker div:only-of-type, .colorSwatchPreview {
  border: 1px solid #212121;
  border-radius: 4px;
}

.css-1j0mc3w-ColorPicker{
  color: #d0d0d0!important;
  text-transform: uppercase;
  font-size: 12px!important;
}

.css-yxnuqv-ColorPicker{
  height: 140px!important;
}
.swatches-spacer-container {
  padding-top: 5px;
  margin-top: 4px;
  padding-bottom: 0px;
  border-top: 1px solid #2121217a;
}

.text-block-48{
  margin-left: 12px;
}

.single-control-container-2.active{
  padding: 7px!important;
}

.relative{
  position: relative;
}

.creator-overlay-container .fullscreen{
  height: 100vh;
}

.viewer-container-with-menu{
  height: calc( 100vh - 40px );
}


.ui.placeholder, .ui.placeholder .image.header:after, .ui.placeholder .line, .ui.placeholder .line:after, .ui.placeholder>:before {
  background-color: #404040;
}

.aspectRatioDrowpdownViewer{
  top: auto!important;
  pointer-events: auto!important;
}

.aspectRatioDrowpdownViewer .item, .aspectRatioDrowpdownViewer .header, .ui.dropdown .menu>.item, .ui.dropdown .menu>.header{
  min-height: auto!important;
  background-image: none!important;
  font-size: 11px!important;
  padding-bottom: 5px!important;
  padding-top: 5px!important;
}

.aspectRatioDrowpdownViewer .header, .ui.dropdown .menu>.header{
  margin-top: 2.5px!important;
  margin-bottom: 0px!important;
  padding-bottom: 0px!important;
}

.ui.dropdown .menu>.divider{
  margin: 2px 0px;
}

.ui.dropdown .menu .active.item {
  background-color: #dddddd;
}

.login-text-field.menu-search input, .dark-mode .ais-SearchBox-input{
  background-color: transparent;
  color: inherit;
}

.dark-mode .ais-SearchBox-input::placeholder, .dark-mode svg.ais-SearchBox-submitIcon path, .dark-mode .ais-SearchBox-input, .dark-mode .ais-SearchBox-resetIcon path{
  color:#d9d9d9!important;
  fill:#d9d9d9!important;
}

.body.dark-mode{
  background-color: #2b2b2b;
}

.viewerclosed  {
  z-index: 2;
}

.image-5-copy{
  cursor: pointer;
}

.ui.inverted.dimmer .ui.loader, .ui.loader{

}


.material-container {
  margin:0!important;
  justify-content: flex-start;
}

.creator-overlay-container-preview{
  overflow-y: auto;
  overflow-x: hidden;
}

.creator-overlay-container-preview{
  position: fixed!important;
}

.my-masonry-grid .ui.loader{
  top: 42% !important;
}

.my-masonry-grid .ui.text.loader{
  padding-top: 1em!important;
  font-size: 0.75em!important;
}

select{
  padding: 0px;
  padding-left: 5px;
}

.regular-button.full-width.w-button:hover{
  color:white;
}

.infoIcon{
  cursor: pointer;
  opacity: 0.75;
}

.infoIcon:hover{
  opacity: 1;
}

.background-video-tutorial{
  z-index: 2;
}

.video-step-container{
  height: 450px;
  width: 800px;
  position: relative;
  margin-left: -25px;
  margin-right: -20px;
}

.video-container-right-side{
  width: 300px;
  height: 225px;
  position: relative;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.tutorial-action{
  font-size: 0.75rem;
  background: #d0d0d0;
  border: solid 1px #999;
  border-radius: 2.5px;
  padding: 4px;
  margin-right: 3px;
}

.tutorial-continue-text{
  font-size: 0.75rem;
  font-style: italic;
  margin-bottom: -25px;
  margin-top: 7.5px;
  color: #555555;
}

.tutorial-step-text-block{
  line-height: 1.25rem;
  text-align:left;
}

.tutorial-step-text-block ol {
  padding-left: 20px;
}

.tutorial-step-text-block li {
  margin-bottom: 7.5px;
}

.tutorial-information-block{
  background-color: #d0d0d0;
  padding: 7.5px;
  border-radius: 3px;
  border: solid 1px #999;
  margin-top: 10px;
  marign-bottom: 10px;
}

.long-tooltip-container{

}

.react-joyride__tooltip{
  animation-name: next-step;
  animation-duration: 300ms;
}


@keyframes next-step {
  from {
    transform: scale(0.5);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
    }
}

.left-tutorial-handler{
  width: 0px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: transparent;
  z-index: 90;
}

span.home-button-tutorial svg{
  padding-top: 5.5px;
}

.regular-button-ui{
  min-height: 30px;
  height: auto!important;
}

.sidebar-img{
  min-width: 19px;
}

.product-detail-panel{
  overflow-y: auto;
}

.product-detail-panel{
  width: 350px;
}

.product-single-color-swatch-detail-view  .delte-color-swatch {
  display: none;
  opacity: 0;
}

.product-single-color-swatch-detail-view:hover  .delte-color-swatch {
  display: block;
  animation-name: opacity-show;
  animation-duration: 300ms;
  opacity: 0.55;
}


@keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.55;
  }
}

.checkmark.icon::after{
  display: none;
}

.home-section{
  height: 350px;
}

.delete-coffe-bean-pouch-hint-image{
  width: 30px;
}

.object-name{
  line-height: 16px;
}


.text-to-input-input-field{
  line-height: 14px;
  height: auto;
  padding-left: 3.5px;
  padding-right: 3.5px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  margin-left: -5px;
  margin-bottom: -3px;

}

.round-dimmer{
  border-radius: 500px;
}

.round-dimmer .ui.text.loader{
  padding-top: 1em!important;
  font-size: 0.75em!important;
}

.round-dimmer .ui.loader {
  top: 42% !important;
}

.modifierContainer{
  max-height: 30vh;
  overflow-y: scroll;
}

.single-object-modifier-container .zl-tooltip{
  left: 10px;
  top: 10px;
}

.single-material-option, .single-object-modifier-container .zl-tooltip-hit-color{
  position: static;
}

.completeOverlay{
  color: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-color-picker{
  position: absolute;
  text-align: center;
  font-size: 0.75rem;
  opacity: 0.75;
  bottom: -30px;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-color-picker-font-element{
  padding: 2px 20px;
  border-radius: 100px;
  background-color: black;
  width: auto;
  cursor: pointer;
  border: 1px solid #999;
}

.css-fp8utj, .css-yxnuqv-ColorPicker{
  height: 140px!important;
}

select .select-inside-creator {
  min-height: 28px!important;
  height: auto!important;

}
