.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

body {
  font-family: Montserrat, sans-serif;
  color: #707070;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
}

.menu-image-link-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: border-color 200ms ease, color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease;
  color: #a5a5a5;
  text-decoration: none;
}

.menu-image-link-container:hover {
  color: #333;
}

.menu-image-link-container.dark-mode {
  color: #d9d9d9;
}

.form-block-2 {
  max-width: 80vw;
  margin-bottom: 0px;
}

.form-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sign-in-main-menu {
  margin-left: 10px;
  font-weight: 400;
}

.header-menu-sign-in {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 15px;
  padding: 4px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #d9d9d9;
  border-radius: 5px;
  -webkit-transition: border-color 200ms ease, color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease;
  color: #a5a5a5;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.header-menu-sign-in:hover {
  border-color: #4d4d4d;
  color: #adadad;
}

.login-text-field {
  height: 49px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
  border-radius: 7px;
}

.login-text-field.menu-search {
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  padding-right: 28px;
  padding-left: 28px;
  border-style: none;
  border-radius: 20px;
  background-color: #f1f1f1;
  font-size: 12px;
}

.login-text-field.menu-search.dark-mode {
  border-style: solid;
  border-color: #333;
  background-color: #2b2b2b;
  box-shadow: 0 1px 6px -9px rgba(0, 0, 0, 0.49);
  color: #d9d9d9;
}

.login-text-field.lastname {
  border-left-style: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.login-text-field.firstname {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.login-text-field.no-margin {
  height: 42px;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 18px;
}

.username {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding: 7px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #b0b1b4;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
}

.username:hover {
  border-color: #333;
}

.menu-logo {
  width: 80px;
}

.menu-logo.dark-mode {
  width: 70px;
  margin-top: 6px;
  color: #d9d9d9;
}

.sign-in-user-image {
  width: 28px;
  height: 28px;
  border-radius: 20px;
}

.search-icon-header {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 15px;
  height: 15px;
  margin-top: 13px;
  margin-right: 16px;
}

.menu-right-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.menu-center-container {
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-image-char {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #1592e6;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.menu-left-container {
  width: 68px;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.sign-up-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 15px;
  padding: 4px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #5dc778;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.sign-up-container:hover {
  color: hsla(0, 0%, 100%, 0.85);
}

.sign-in-main-menu-normal {
  font-weight: 400;
}

.home-section {
  margin-bottom: 50px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #0f5014;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.38)), to(rgba(0, 0, 0, 0.38))), url('../images/mint_001.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url('../images/mint_001.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.text-field {
  height: 50px;
  border-radius: 9px;
}

.text-field.dark-mode {
  padding-left: 21px;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 100px;
  background-color: #2b2b2b;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.57);
  font-size: 14px;
}

.heading {
  color: #d9d9d9;
}

.paragraph {
  margin-bottom: 34px;
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
}

.div-block-2 {
  width: 505px;
}

.form-3 {
  width: 720px;
  margin-right: auto;
  margin-left: auto;
}

.text-block-21 {
  color: hsla(0, 0%, 85.1%, 0.78);
  font-size: 12px;
}

.navbar-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  padding: 11px 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.navbar-2.dark-mode {
  border-bottom: 1px solid #333;
  background-color: #404040;
  color: #d9d9d9;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.creator-overlay-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 10;
  display: block;
  width: 100vw;
  height: 100vh;
  padding: 5vh 70px;
  background-color: rgba(0, 0, 0, 0.6);
}

.creator-overlay-container.fullscreen {
  padding: 0vh 0px;
}

.overlay-container {
  display: block;
  min-height: 100%;
  margin-top: -20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px 4px rgba(0, 0, 0, 0.1);
}

.overlay-container.fullscreen {
  margin-top: 0px;
}

.image-5 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 17px;
  height: 17px;
  margin-top: 10px;
  margin-left: 10px;
  opacity: 0.8;
}

.unity-viewer-container {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #7e7e7e;
  background-image: url('../images/paul-skorupskas-7KLa-xLbSXA-unsplash.jpg');
  background-size: cover;
}

.viewer-side-menu-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 310px;
  border: 2px none #000;
  background-color: #404040;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.viewer-side-menu-container.hide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: none;
}

.viewer-menu-header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.viewer-heading-img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.text-block-22 {
  font-weight: 600;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 20px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
}

.image-6 {
  width: 8px;
  height: 8px;
  opacity: 0.76;
}

.side-menu-in-viewer {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 17px;
  margin-bottom: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.in-viewer-option-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.47;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.in-viewer-option-container:hover {
  opacity: 1;
}

.in-viewer-option-container.active {
  opacity: 1;
  cursor: pointer;
}

.div-block-5 {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #fff;
}

.image-7 {
  padding: 8px;
}

.side-menu-in-viewer-heading {
  margin-right: 15px;
  color: #fff;
  font-weight: 500;
}

.text-block-23 {
  font-size: 16px;
  text-shadow: 1px 1px 4px #000;
}

.objects-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.object-container {
  position: relative;
  width: 48%;
  margin: 1%;
  padding: 0px;
  cursor: pointer;
}

.object-title-container {
  display: none;
}

.object-title-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
}

.image-8 {
  position: relative;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
}

.viewer-container-with-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90vh;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.viewer-container-with-menu.fullscreen {
  height: 96vh;
}

.gravity {
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: #7ab878;
  -webkit-transform: translate(16px, 0px);
  -ms-transform: translate(16px, 0px);
  transform: translate(16px, 0px);
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.gravity.off {
  border-style: solid;
  border-width: 2px;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
  opacity: 1;
  -webkit-transform: translate(3px, 0px);
  -ms-transform: translate(3px, 0px);
  transform: translate(3px, 0px);
}

.form-inside-creator {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.toggle-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 22px;
  margin-left: 10px;
  padding-right: 4px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #333;
  border-radius: 20px;
  background-color: #5e5e5e;
  cursor: pointer;
}

.toggle-container.off {
  height: 22px;
  border: 1px solid #333;
  background-color: #5e5e5e;
}

.select-inside-creator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: 28px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 2px;
  background-color: #5e5e5e;
  opacity: 0.65;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}

.select-inside-creator:hover {
  opacity: 1;
}

.settings-text {
  width: 80px;
  margin-left: 0px;
  font-family: Montserrat, sans-serif;
  color: #d9d9d9;
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
}

.settings-text.shorter {
  width: 60px;
  margin-right: 3px;
}

.settings-container-settings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 11px;
  padding-bottom: 11px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none rgba(51, 51, 51, 0.11);
  background-color: transparent;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
}

.settings-container-settings.last {
  margin-bottom: 0px;
}

.settings-container {
  padding-right: 0px;
  padding-left: 0px;
}

.aspect-landscape-side {
  width: 100px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.26);
}

.aspect-landscape-side.right {
  border-top-right-radius: 0px;
}

.aspect-landscape-side.left {
  border-top-left-radius: 0px;
}

.aspect-landscape {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.aspect-ratio {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

.aspect-portrait {
  display: none;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.aspect-portrait-side {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.26);
}

.aspect-portrait-side.top {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.photo-button {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: 25px;
  width: 80px;
  height: 80px;
  padding: 22px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 1px 6px 0 #000;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  cursor: pointer;
}

.photo-button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.photo-button-container {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 17px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.image-element-class {
  position: relative;
  cursor: pointer;
}

.image-element-class:hover {
  opacity: 1;
}

.image-element-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.8)), color-stop(50%, hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0.8)));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0) 50%, hsla(0, 0%, 100%, 0.8));
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.image-element-hover:hover {
  opacity: 1;
}

.body {
  -webkit-transition: opacity 200ms ease, opacity 200ms ease;
  transition: opacity 200ms ease, opacity 200ms ease;
}

.info-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -15px -20px 20px;
  padding: 8px 13px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #4995ec;
  font-family: Montserrat, sans-serif;
  color: #fff;
}

.info-container-heading {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.info-container-explaination {
  color: hsla(0, 0%, 100%, 0.9);
  font-size: 10px;
  line-height: 14px;
}

.info-container-symbol-container {
  width: 28px;
  height: 28px;
  margin-right: 15px;
}

.object-add-hover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.8)), color-stop(50%, hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0.8)));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0) 50%, hsla(0, 0%, 100%, 0.8));
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.object-add-hover:hover {
  opacity: 1;
}

.add-normally-button {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 7px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #35a56f;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.add-normally-button:hover {
  background-color: #23774e;
}

.text-block-25 {
  color: #fff;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
}

.image-10 {
  width: 15px;
  height: 15px;
  margin-right: 7.5px;
}

.image-11 {
  width: 35px;
}

.viewer-submenu-header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-input-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.form-block-3 {
  margin-bottom: 0px;
}

.input-unit {
  width: 45px;
  height: 30px;
  margin-bottom: 0px;
  padding-right: 8px;
  padding-left: 8px;
  border-style: solid none solid solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 5px 0px 0px 5px;
  background-color: #e6e6e6;
  opacity: 0.65;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 10px;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
}

.input-unit-unit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  padding: 0px 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #e6e6e6;
  opacity: 0.65;
  font-family: Montserrat, sans-serif;
  font-size: 9px;
  font-weight: 700;
}

.slider-transform {
  position: relative;
  height: 2px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #c4c4c4;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.point-transform {
  position: absolute;
  left: 47%;
  width: 13px;
  height: 13px;
  margin-top: -6px;
  border-radius: 100px;
  background-color: #696666;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.61);
  cursor: pointer;
}

.side-menu-main-tab-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-bottom: 25px;
  border: 1px none #c5c5c5;
  border-radius: 10px;
  background-color: #fff;
  font-size: 10px;
}

.side-menu-single-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.side-menu-single-tab.left {
  border-style: solid none solid solid;
  border-width: 1px;
  border-color: #707070;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease, opacity 200ms ease;
  transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease, opacity 200ms ease;
}

.side-menu-single-tab.left:hover {
  border-style: solid none solid solid;
  border-color: #1592e6;
  background-color: rgba(206, 228, 243, 0.24);
  opacity: 0.74;
  color: #1592e6;
}

.side-menu-single-tab.right {
  border: 1px solid #707070;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease;
  color: #707070;
}

.side-menu-single-tab.right:hover {
  border-color: #1592e6;
  background-color: rgba(206, 228, 243, 0.24);
  opacity: 0.74;
  color: #1592e6;
}

.objectinformationtab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 17px;
}

.objectimagecontainer {
  position: relative;
  width: 75px;
  height: auto;
  border-radius: 8px;
  background-color: #a5a5a5;
}

.objectinformationtextcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-12 {
  position: static;
  left: 16px;
  top: 16px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.13);
}

.objectinformationname {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 18px;
}

.objectinformationtype {
  opacity: 1;
  color: #b0b1b4;
  font-size: 11px;
  font-style: italic;
}

.propertycontainer {
  margin-top: 40px;
}

.propertytitle {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #707070;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.propertytitle.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.propertytitleelement {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: none;
}

.propertyelementcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rotationbuttoncontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: 11px;
  padding: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b0b1b4;
  border-radius: 5px;
  background-color: hsla(0, 0%, 100%, 0);
  -webkit-transition: border-color 200ms ease, color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease;
  color: #707070;
  cursor: pointer;
}

.rotationbuttoncontainer:hover {
  border-color: #1592e6;
  background-color: rgba(222, 230, 236, 0.46);
  color: #1592e6;
}

.rotationbuttoncontainer.active {
  border-color: transparent;
  background-color: #dee6ec;
  color: #1592e6;
}

.rotationbuttoncontainer.first {
  margin-left: 0px;
}

.rotationbuttoncontainer.withtext {
  width: auto;
  font-weight: 600;
}

.rotationbuttoncontainer.withtext.first {
  font-size: 10px;
}

.svg-toggle-object-panel {
  width: 18px;
  height: 18px;
  margin-right: 0px;
}

.html-embed {
  width: 20px;
  height: 20px;
}

.html-embed:hover {
  color: #1592e6;
}

.html-embed.smaller {
  width: 17px;
  height: 17px;
}

.objectpaneldeletebutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ff0048;
  border-radius: 5px;
  -webkit-transition: opacity 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
  transition: opacity 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
  color: #ff0048;
  cursor: pointer;
}

.objectpaneldeletebutton:hover {
  border-color: #ff0048;
  background-color: rgba(255, 0, 72, 0.14);
  color: #ff0048;
}

.verticalspreadapart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 100%;
  min-height: 90%;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.tab-container-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 23px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tab-element-container-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 13px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  opacity: 1;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.tab-element-container-panel:hover {
  border-radius: 5px;
  background-color: rgba(222, 230, 236, 0.46);
  color: #1592e6;
}

.tab-element-container-panel.hiddenpanel {
  opacity: 0;
  cursor: default;
}

.tab-element-container-panel.active-new {
  height: 36px;
  padding: 7px 13px;
  border-radius: 5px;
  background-color: #dee6ec;
  color: #1592e6;
}

.tab-menu-text-panel {
  margin-left: 8px;
  font-weight: 600;
}

.svg-toggle-object-panel-icon {
  width: 20px;
  height: 20px;
  margin-right: 0px;
}

.cam-viewer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.save-area-cam-viewer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.15);
}

.save-area-cam-viewer.sides {
  width: 120px;
  height: 100%;
}

.save-area-cam-viewer.bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cam-viewer-save-area-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.aspect-container {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.corner-image {
  position: absolute;
  width: 70px;
  height: 70px;
}

.corner-image.top-left {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.corner-image.top-right {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.corner-image.bottom-left {
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.corner-image.bottom-right {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cornerblocks {
  width: 120px;
  height: 100%;
}

.camera-viewer-inputs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  padding-top: 13px;
  padding-bottom: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.camer-button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 100px;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  cursor: pointer;
}

.camer-button-container:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.div-block-7 {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 100px;
  background-color: hsla(0, 0%, 100%, 0.18);
}

.grid-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0);
  border-radius: 100px;
  background-color: hsla(0, 0%, 100%, 0.34);
  -webkit-transition: border 200ms ease, color 200ms ease;
  transition: border 200ms ease, color 200ms ease;
  color: hsla(0, 0%, 100%, 0.53);
  cursor: pointer;
}

.grid-button:hover {
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 68%, 0.71);
  color: hsla(0, 0%, 100%, 0.91);
}

.grid-button.left {
  margin-right: 20px;
  margin-left: 0px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-button.right {
  position: relative;
  margin-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.grid-button.left {
  margin-right: 20px;
  margin-left: 0px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}

.grid-container.vert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.grid-horizontal {
  width: 100%;
  height: 33.3333%;
}

.grid-horizontal.lines {
  border-top: 1.5px solid hsla(0, 0%, 100%, 0.5);
  border-bottom: 1.5px solid hsla(0, 0%, 100%, 0.5);
}

.grid-vert {
  width: 33.3333%;
  height: 100%;
}

.grid-vert.lines {
  border-style: none solid;
  border-width: 1.5px;
  border-color: hsla(0, 0%, 100%, 0.5);
}

.colorlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 14px;
  padding: 3px 7px;
  border-radius: 20px;
  background-color: rgba(206, 228, 243, 0.35);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #1592e6;
  font-size: 10px;
  line-height: 15px;
  text-decoration: none;
  cursor: pointer;
}

.colorlink:hover {
  background-color: #cee4f3;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-27 {
  margin-left: 10px;
  font-size: 11px;
}

.backend-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.backend-body-container {
  position: relative;
  padding: 44px 35px 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fbfcff;
}

.backend-body-container.dark-mode {
  background-color: #2b2b2b;
}

.backend-menu-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 100vh;
  max-width: 250px;
  min-width: 250px;
  padding: 20px 50px 20px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 20px -1px rgba(63, 69, 87, 0.22);
}

.backend-menu-container.dark-mode {
  background-color: #404040;
  color: #d9d9d9;
}

.logo-img {
  width: 80px;
}

.link-block-3 {
  margin-top: 35px;
  margin-bottom: 80px;
  margin-left: 10px;
}

.photo-icon {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: #898989;
}

.camer-button-icon-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19px;
  height: 19px;
  max-height: 22px;
  max-width: 22px;
  margin-right: 25px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #898989;
  border-radius: 20px;
  opacity: 0.63;
  cursor: pointer;
}

.side-bar-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 10px;
  padding: 12px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #898989;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.side-bar-link:hover {
  color: #202020;
}

.side-bar-link.dark-mode {
  color: #d9d9d9;
}

.side-bar-link.active {
  background-color: #f2f5fb;
  color: #1592e6;
}

.active-symbol {
  position: absolute;
  left: -40px;
  top: -9px;
  right: auto;
  bottom: auto;
  width: 5px;
  height: 50px;
}

.active-symbol.active {
  background-color: #1592e6;
}

.side-bar {
  font-size: 12px;
}

.side-bar.active.dark-mode {
  color: #d9d9d9;
}

.sidebar-img {
  width: 19px;
  margin-right: 25px;
}

.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333;
  font-size: 31px;
  line-height: 31px;
  font-weight: 600;
}

.backend-header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.backend-header-container.dark-mode {
  color: #d9d9d9;
}

.user-backend-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 42px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #404040;
  cursor: pointer;
}

.backend-user-name {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 600;
}

.backend-photo-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 28.3%;
  margin-right: 2.5%;
  margin-bottom: 60px;
  margin-left: 2.5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, box-shadow 200ms ease;
  transition: transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  cursor: pointer;
}

.backend-photo-card:hover {
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.19);
  -webkit-transform: translate(0px, 2px);
  -ms-transform: translate(0px, 2px);
  transform: translate(0px, 2px);
}

.backend-photo-card.small {
  width: 20%;
  margin-right: 0%;
  margin-bottom: 0px;
  margin-left: 0%;
  border-radius: 0px;
}

.backend-photo-card.small:hover {
  position: static;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.backend-photo-card.list {
  width: 100%;
  margin-right: 0%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 46%, 0.26);
  border-radius: 0px;
  box-shadow: none;
}

.backend-photo-card.list:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.backend-photo-card.user {
  width: 100%;
  margin-right: 0%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 46%, 0.26);
  border-radius: 0px;
  box-shadow: none;
}

.backend-photo-card.user:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.backend-photo-card.dark-mode {
  background-color: #2b2b2b;
}

.backend-photo-preview {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.backend-photo-preview.list {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.backend-photo-preview.small {
  border-radius: 0px;
}

.backend-photo-information-conatiner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #fff;
  opacity: 1;
}

.backend-photo-information-conatiner.small {
  display: none;
}

.backend-photo-information-conatiner.list {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px none #dadada;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
}

.backend-photo-information-conatiner.user {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px none #dadada;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
}

.backend-photo-information-conatiner.locked {
  background-color: #fff;
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.backend-photo-information-conatiner.empty {
  background-color: #cecece;
}

.backend-photo-information-conatiner.dark-mode {
  background-color: #404040;
  color: #d9d9d9;
}

.backend-photo-information-img {
  width: 38px;
  height: 38px;
  margin-right: 15px;
  border-radius: 100px;
  background-image: url('../images/water_ball_unity.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.backend-photo-information-img.waitlist {
  background-color: #f5b144;
  background-image: none;
}

.backend-photo-information-img.rendering {
  background-color: #1592e6;
  background-image: none;
}

.backend-photo-information-img.list {
  display: none;
}

.backend-photo-information-img.empty {
  background-color: #888;
  background-image: none;
}

.backend-photo-information-header {
  color: #333;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.backend-photo-information-header.empty {
  background-color: #888;
  color: #888;
}

.backend-photo-information-header.dark-mode {
  color: #d9d9d9;
}

.backend-photo-information-subtext {
  margin-top: 6px;
  font-size: 8px;
  line-height: 9px;
}

.backend-photo-information-subtext.empty {
  background-color: #888;
  color: #888;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -2.5%;
  margin-left: -2.5%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block-11.small {
  margin-right: 0%;
  margin-left: 0%;
}

.div-block-11.list {
  margin-right: 0%;
  margin-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.edit-render {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  height: 25px;
  padding: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fafafa;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.edit-render:hover {
  opacity: 1;
}

.div-block-12 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.div-block-12.larger {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-12.list {
  width: 80px;
  height: 80px;
  font-size: 7px;
}

.backend-photo-overlay-container {
  position: fixed;
  left: 300px;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100vh;
  padding: 5vh 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-photo-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 700px;
  min-height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 0px;
  background-color: #f2f5fb;
  box-shadow: 0 1px 3px 4px rgba(0, 0, 0, 0.1);
}

.image-13 {
  width: 100%;
  height: 100%;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 72px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 79px;
  margin-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.div-block-15 {
  padding: 8px 25px;
  border: 2px solid #35bf4a;
  border-radius: 5px;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #35bf4a;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.div-block-15:hover {
  background-color: rgba(53, 191, 74, 0.1);
}

.smiley {
  height: 27px;
  margin-right: 7px;
  opacity: 0.4;
  -webkit-transition: color 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, opacity 200ms ease;
  cursor: pointer;
}

.smiley.red:hover {
  opacity: 1;
  color: #fd4e91;
}

.smiley.red.active {
  opacity: 1;
  color: #fd4e91;
}

.smiley.green {
  height: 26px;
}

.smiley.green:hover {
  opacity: 1;
  color: #3bb757;
}

.smiley.green.active {
  opacity: 1;
  color: #3bb757;
}

.backend-other-status-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 8px;
  border-color: #f5b144;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #e49d3b;
  text-align: center;
}

.backend-other-status-container.rendering {
  border-color: #1592e6;
  background-color: #1073b6;
}

.backend-other-status-container.list {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.backend-other-status-container.small {
  border-radius: 0px;
}

.text-block-29 {
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #7e8da2;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
}

.social-login:hover {
  color: #4886ff;
}

.login-panel-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fafafc;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f2f5fb), to(#f2f5fb)), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(180deg, #f2f5fb, #f2f5fb), linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block-8 {
  margin-left: 10px;
}

.text-block-7 {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #707070;
  font-size: 12px;
  text-align: center;
}

.text-block-7.less-margin {
  margin-bottom: 10px;
}

.heading-3 {
  margin-bottom: 35px;
  color: #333;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.link-block {
  padding: 8px 15px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

.link-block:hover {
  color: hsla(0, 0%, 43.9%, 0.8);
}

.bold-text {
  color: #333;
}

.regular-button {
  padding: 17px 55px;
  border-radius: 7px;
  background-color: #1592e6;
  box-shadow: 0 2px 9px 0 rgba(72, 134, 255, 0.41);
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  color: #fff;
  cursor: pointer;
}

.regular-button:hover {
  box-shadow: 0 2px 6px 0 rgba(72, 134, 255, 0.41);
  -webkit-transform: translate(0px, 3px);
  -ms-transform: translate(0px, 3px);
  transform: translate(0px, 3px);
}

.regular-button.full-width {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.regular-button.full-width.bold {
  border: 1px solid #1592e6;
  background-color: #1592e6;
  font-weight: 600;
}

.regular-button.full-width.bold:hover {
  -webkit-transform: translate(0px, 3px);
  -ms-transform: translate(0px, 3px);
  transform: translate(0px, 3px);
}

.regular-button.full-width.dark-mode {
  color: #d9d9d9;
}

.regular-button.greyed {
  background-color: #b0b1b4;
  box-shadow: none;
}

.regular-button.resend-user-invitation-button {
  padding: 8px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(21, 146, 230, 0.18);
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  color: #1592e6;
  font-size: 10px;
  font-weight: 600;
}

.regular-button.add-team-member {
  padding: 12px 22px;
  font-size: 10px;
  font-weight: 500;
}

.regular-button.credits {
  margin-top: 7px;
  padding: 7px 30px;
  border-radius: 20px;
}

.social-logo {
  width: 15px;
  height: 15px;
}

.social-logo.large {
  width: 17px;
  height: 17px;
}

.div-block-18 {
  width: 460px;
  max-width: 460px;
  padding: 31px;
  border-radius: 4px;
}

.full-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #cee4f3;
}

.text-block-6 {
  font-weight: 600;
}

.social-login-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.07);
}

.login-panel-right-header-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.link {
  color: #1592e6;
  font-weight: 700;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.div-block-19 {
  position: relative;
}

.forgot {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 9px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #f1f4f7;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  color: #aeb5be;
  font-size: 11px;
}

.forgot:hover {
  background-color: #1592e6;
  color: #fff;
}

.logo-login-container {
  width: 80px;
  margin-top: 2px;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-30 {
  margin-top: 100px;
  margin-bottom: 14px;
}

.container-flex-n1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu3 {
  position: absolute;
  right: -35px;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 390px;
}

.line-seperator {
  width: 100%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba(47, 41, 90, 0.06);
}

.nav_dropdown {
  position: absolute;
  top: 70px;
  right: 0px;
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 40px 0 rgba(223, 225, 229, 0.57);
}

.menu_wrapper {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.menu-title-sub {
  color: #637178;
  font-size: 13px;
}

.nav-01 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 70px;
  padding-right: 5%;
  padding-left: 5%;
  background-color: #fff;
}

.dropdown-menu1 {
  position: absolute;
  right: 10px;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 390px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-width: 85px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  font-family: Montserrat, sans-serif;
  color: #707070;
  font-size: 15px;
  text-decoration: none;
}

.nav-link-wrap:hover {
  color: #363636;
}

.block-50-right {
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
}

.link-block-4 {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  text-decoration: none;
}

.link-block-4:hover {
  border-radius: 6px;
  background-color: #f9fbff;
}

.nav-link {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.block-50-left {
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
}

.menu-title-t {
  margin-bottom: 4px;
  color: #1d1f31;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.dropdown-menu2 {
  position: absolute;
  right: -20px;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 430px;
  height: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.nav_items {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.user-hover-menu {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 8;
  display: none;
  width: 210px;
  margin-top: 45px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 7px 2px rgba(193, 205, 213, 0.47);
  font-size: 12px;
}

.user-hover-single-element-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.user-hover-single-element-container:hover {
  color: #333;
}

.user-hover-single-element-container.last {
  border-bottom-style: none;
}

.hover-icon {
  width: 30px;
  height: 20px;
  min-width: 3px;
  margin-right: 10px;
}

.hover-icon.team {
  height: 25px;
}

.error-container {
  height: 75px;
}

.error-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #ff006a;
  border-radius: 5px;
  background-color: rgba(255, 0, 106, 0.81);
  color: #fff;
}

.list-settings-sort-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-settings-sort-container.dark-mode {
  color: #d9d9d9;
}

.list-view-types-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: rgba(109, 109, 109, 0.31);
}

.list-sort-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tiles-size-icon {
  margin-right: 9px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  cursor: pointer;
}

.tiles-size-icon:hover {
  color: #1592e6;
}

.tiles-size-icon.active {
  color: #1592e6;
}

.tiles-size-icon.dark-mode {
  color: hsla(0, 0%, 85.1%, 0.5);
}

.invited-org {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.invite-org-name {
  padding-top: 22px;
  padding-bottom: 22px;
  color: #333;
  font-size: 20px;
}

.line-spacer {
  width: 70%;
  height: 1px;
  margin: 20px auto;
  background-color: hsla(0, 0%, 52%, 0.35);
}

.line-spacer.small {
  width: 25%;
  margin-right: 0px;
  margin-left: 0px;
}

.text-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #707070;
  text-decoration: none;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.or-text {
  margin-right: 20px;
  margin-left: 20px;
  color: hsla(0, 0%, 52.2%, 0.35);
}

.html-embed-2 {
  width: 10px;
  height: 10px;
  margin-top: -11px;
}

.credits-text {
  margin-right: 15px;
  font-size: 14px;
}

.credits-icon-copy {
  width: 15px;
  height: 15px;
  min-width: 3px;
  margin-right: 5px;
}

.credits-icon-copy.team {
  height: 25px;
}

.credit-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-invitation-pending {
  margin-right: 15px;
  padding: 4px 20px;
  background-color: #e2e2e2;
  font-size: 10px;
  font-weight: 600;
}

.heading-2-copy {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}

.addteammembercontainer {
  width: 50%;
  padding-right: 0px;
  padding-left: 0px;
}

.form-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-23 {
  margin-right: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block-4 {
  margin-bottom: 0px;
}

.cta3-item-wrap {
  padding: 20px 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(17, 18, 18, 0.06);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  text-align: left;
}

.cta3-item-wrap.cta3-recommended-option {
  padding-top: 30px;
  border-width: 2px;
  border-color: #1592e6;
}

.cta3-wide-button-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

._5-col {
  display: inline-block;
  width: 30%;
  margin-right: 20px;
}

._5-col._5-col-last {
  margin-right: 0px;
}

.text-16 {
  display: inline-block;
  margin-bottom: auto;
  font-size: 14px;
  line-height: 26px;
}

.text-16.text-16-60 {
  opacity: 0.6;
}

.decoline {
  width: 30px;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #d2d2d2;
}

.decoline.decoline-dark {
  background-color: hsla(0, 0%, 82.4%, 0.2);
}

.cta3-icon {
  margin-right: 12px;
}

.btn {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 11px 25px;
  float: none;
  border-radius: 4px;
  background-color: #1991eb;
  -webkit-transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, transform 400ms ease, -webkit-transform 400ms ease;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.btn:hover {
  border-color: #0c6eb8;
  background-color: #1386dd;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
  color: #fff;
}

.btn:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}

.btn.btn-outline-light {
  border: 1px solid #1592e6;
  background-color: transparent;
  color: #1592e6;
  font-size: 12px;
  font-weight: 600;
}

.btn.btn-outline-light:hover {
  border-color: rgba(17, 18, 18, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.btn.btn-outline-light:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.cta3-item-point {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.h2-jumbo {
  font-size: 30px;
  line-height: 56px;
  font-weight: 300;
}

.label-text {
  color: #fff;
  font-size: 10px;
  line-height: 17px;
  font-weight: 500;
  text-transform: uppercase;
}

.cta3-options-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-big {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px 35px;
  float: none;
  border-radius: 4px;
  background-color: #1592e6;
  -webkit-transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, -webkit-transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, transform 400ms ease;
  transition: color 200ms ease, box-shadow 200ms ease, background-color 200ms ease, transform 400ms ease, -webkit-transform 400ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
}

.btn-big:hover {
  border-color: #0c6eb8;
  background-color: #1386dd;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
  color: #fff;
}

.btn-big:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}

.label {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #1592e6;
}

.text-20 {
  opacity: 1;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

.cta3-item-points-wrapper {
  margin-top: 25px;
  margin-bottom: 10px;
}

.bold-text-2 {
  color: #1592e6;
  font-size: 13px;
}

.select-photo-button {
  padding: 2px 8px;
  border: 1px solid #1592e6;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  color: #1592e6;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
}

.select-photo-button:hover {
  background-color: rgba(21, 146, 230, 0.17);
  color: #1592e6;
}

.select-photo-button.active {
  background-color: #1592e6;
  color: #fff;
}

.selected-container {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: hsla(0, 0%, 100%, 0.63);
  color: #464646;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.selectedsvg {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: 10px;
  width: 25px;
  height: 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 20px;
  background-color: #fff;
  color: #1592e6;
}

.delete-photos-button {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: #707070;
  cursor: pointer;
}

.delete-photos-button.dark-mode {
  color: #d9d9d9;
}

.home-category-items {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
}

._2-col {
  display: inline-block;
  width: 140px;
  margin-right: 20px;
}

._2-col._2-col-last {
  margin-right: 0px;
}

.home-category-linkitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 18px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(17, 18, 18, 0.06);
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(17, 18, 18, 0.06);
  -webkit-transition: color 400ms ease, opacity 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  transition: color 400ms ease, opacity 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  transition: color 400ms ease, transform 400ms ease, opacity 400ms ease, border 400ms ease;
  transition: color 400ms ease, transform 400ms ease, opacity 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  color: #111212;
  font-size: 11px;
  text-decoration: none;
}

.home-category-linkitem:hover {
  border-color: #1592e6;
  box-shadow: 0 1px 8px 0 rgba(17, 18, 18, 0.15);
}

.home-category-linkitem:active {
  box-shadow: 0 1px 6px 0 rgba(17, 18, 18, 0.15);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}

.text-16-2 {
  display: inline-block;
  color: #333;
  font-size: 13px;
  line-height: 26px;
  font-weight: 600;
}

._4-col {
  display: inline-block;
  width: 300px;
  margin-right: 20px;
}

._4-col._4-col-last {
  display: inline-block;
  margin-right: 0px;
}

.nav-bar-container {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 4;
}

.categories-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 50px;
  background-color: #fff;
}

.categories-header.dark-mode {
  background-color: #404040;
  color: #d9d9d9;
}

.home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.scroll-horiztonal-categories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.categorie-link-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-right: 18px;
  margin-left: 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-bottom: 3px solid transparent;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #707070;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

.categorie-link-container:hover {
  color: #333;
}

.categorie-link-container.active {
  border-bottom-color: #333;
  color: #333;
}

.categorie-link-container.dark-mode {
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease, color 200ms ease;
  transition: opacity 200ms ease, color 200ms ease;
  color: #d9d9d9;
  font-weight: 500;
}

.categorie-link-container.dark-mode:hover {
  opacity: 0.7;
}

.categorie-link-container.dark-mode.active {
  border-bottom-color: #d9d9d9;
  opacity: 1;
}

.text-block-32 {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.text-block-32.dark-mode {
  opacity: 0.8;
  color: #d9d9d9;
}

.vertical-spacer {
  width: 1px;
  height: 30px;
  background-color: #b0b1b4;
}

.vertical-spacer.dark-mode {
  background-color: #d9d9d9;
}

.div-block-25 {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-26 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 40px;
  height: 100%;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
}

.div-block-26.dark-mode {
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#404040));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #404040);
}

.div-block-27 {
  position: relative;
  height: 30px;
}

.top-spacer-credits {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 6;
  height: 30px;
  margin-top: -30px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(71%, #f2f5fb));
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #f2f5fb 71%);
}

.div-block-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #626262;
}

.div-block-29 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block-33 {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.text-block-34 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  height: 27px;
  padding-right: 15px;
  padding-left: 15px;
  opacity: 0.51;
  color: #fff;
  font-size: 9px;
  line-height: 14px;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 13px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.user-hover-single-element-container-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 10px 22px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.user-hover-single-element-container-large:hover {
  color: #333;
}

.user-hover-single-element-container-large.last {
  border-bottom-style: none;
}

.text-block-35 {
  margin-right: 10px;
  font-size: 20px;
  font-weight: 700;
}

.refill-credits-button {
  margin-top: 8px;
  padding: 7px 21px;
  border-radius: 20px;
  background-color: #1592e6;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  cursor: pointer;
}

.refill-credits-button:hover {
  background-color: #2973a5;
}

.credits-information-text {
  margin-top: 4px;
  color: #707070;
  font-size: 10px;
}

.div-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.current-credits-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 10px 22px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #d4d4d4;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.current-credits-container:hover {
  color: #333;
}

.current-credits-container.last {
  border-bottom-style: none;
}

.div-block-31-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
}

.text-block-35-copy {
  margin-right: 10px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
}

.hover-icon-copy {
  width: 80px;
  height: 80px;
  min-width: 3px;
  margin-right: 10px;
  font-size: 40px;
  line-height: 40px;
}

.hover-icon-copy.team {
  height: 25px;
}

.credits-information-text-copy {
  margin-top: 4px;
  color: #707070;
  font-size: 14px;
}

.div-block-24-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.text-block-36 {
  text-align: center;
}

.text-block-35-copy-copy {
  margin-right: 10px;
  font-size: 80px;
  line-height: 80px;
  font-weight: 700;
}

.heading-2-copy-copy {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}

.help-viewer-container {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
}

.controls-help-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.help-navigation-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.help-menu-item {
  margin-right: 18px;
  margin-left: 18px;
  padding-bottom: 6px;
  border-bottom: 3px solid transparent;
  opacity: 0.7;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.help-menu-item.active {
  border-bottom-color: #1592e6;
  opacity: 1;
}

.div-block-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.91;
  color: #d9d9d9;
  text-align: center;
}

.image-14 {
  width: 14px;
  margin-right: 5px;
}

.text-block-38 {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.text-block-39 {
  display: block;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 11px;
  line-height: 11px;
  text-align: left;
}

.image-5-copy {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 17px;
  height: 17px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
}

.footer-more {
  padding: 25px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.help-symbol {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.help-symbol:hover {
  opacity: 1;
}

.html-embed-3 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.tab-element-container-panel-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 13px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  opacity: 1;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.tab-element-container-panel-2:hover {
  border-radius: 5px;
  background-color: rgba(222, 230, 236, 0.46);
  color: #1592e6;
}

.tab-element-container-panel-2.active-new {
  height: 36px;
  padding: 7px 13px;
  border-radius: 5px;
  background-color: #dee6ec;
  color: #1592e6;
}

.tab-element-container-panel-2.hiddenpanel {
  opacity: 0;
  cursor: default;
}

.addobjectscategories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: 20px;
  margin-left: -7px;
  padding-bottom: 17px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.colorlinkcategoryheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 3px 7px 3px 0px;
  border-radius: 20px;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #d9d9d9;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.colorlinkcategory {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 3px 7px;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 20px;
  background-color: #2b2b2b;
  opacity: 0.6;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #d9d9d9;
  font-size: 10px;
  line-height: 15px;
  text-decoration: none;
  cursor: pointer;
}

.colorlinkcategory:hover {
  opacity: 1;
}

.colorlinkcategory.active {
  opacity: 1;
  color: #fff;
}

.text-block-40 {
  position: absolute;
  left: auto;
  top: 5px;
  right: 5px;
  bottom: auto;
  z-index: 4;
  padding: 0px 5px;
  border-radius: 3px;
  background-color: #1592e6;
  color: #fff;
  font-size: 7px;
  line-height: 15px;
  font-weight: 500;
}

.div-block-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.undo-redo-container {
  position: absolute;
  left: 37.5px;
  top: 9px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.undo-redo-img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.undo-redo-img:hover {
  opacity: 1;
}

.viewerclosed {
  position: fixed;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 230px;
  padding: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f0f0f0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.22);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.viewerclosed:hover {
  background-color: #ebebeb;
}

.viewerclosed.dark-mode {
  border-style: solid solid none;
  border-width: 1px;
  border-color: #333;
  background-color: #404040;
  color: #d9d9d9;
}

.image-15 {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 0px;
}

.text-block-41 {
  line-height: 14px;
}

.credits-viewer-container {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 63px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.hr-white-vertical {
  width: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  opacity: 0.75;
}

.credits-information-text-half {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
}

.div-block-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.render-preview-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #1592e6;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.render-preview-button:hover {
  background-color: #156fac;
}

.div-block-36 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-16 {
  width: 22px;
}

.credits-left-text {
  margin-right: 30px;
}

.credits-amount-text {
  margin-right: 5px;
}

.heading-4 {
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.text-block-42 {
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 35px;
}

.bold-text-3 {
  font-weight: 600;
}

.text-block-43 {
  margin-bottom: 25px;
  opacity: 0.51;
  font-size: 10px;
}

.div-block-37 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block-44 {
  margin-left: 20px;
  opacity: 0.63;
  font-size: 10px;
  line-height: 20px;
  font-weight: 300;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button-label {
  margin-left: 20px;
}

.render-quality-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.render-quality-button:hover {
  background-color: rgba(255, 255, 255, 0.13);
}

.credits-viewer-container-copy {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 63px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.credits-viewer-container-copy.photos-overlay-container {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 5;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.my-photos-main-container.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.image-17 {
  width: 50%;
  max-width: 50%;
  margin: 35px;
  box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, 0.51);
}

.credits-information-text-half-photos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #fff;
}

.image-18 {
  width: 12px;
  margin-right: 10px;
}

.link-2 {
  color: #fff;
  font-size: 10px;
  text-decoration: none;
}

.div-block-38 {
  margin-top: 15px;
  opacity: 0.44;
  text-align: center;
}

.div-block-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: -40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.44;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.text-block-45 {
  margin-right: 25px;
}

.div-block-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.unlock-cloud-button {
  width: 25px;
  height: 25px;
  margin-left: auto;
  opacity: 1;
  color: #707070;
  cursor: pointer;
}

.unlock-cloud-button.unlocked {
  padding: 0px;
  opacity: 1;
  color: #1592e6;
}

.backend-photo-unlock-container {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.backend-photo-unlock-container.list {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.backend-photo-unlock-container.small {
  border-radius: 0px;
}

.locked-symbol {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  opacity: 1;
  cursor: pointer;
}

.locked-symbol.unlocked {
  padding: 0px;
  opacity: 1;
  color: #1592e6;
}

.text-block-46 {
  font-weight: 600;
}

.unlock-container {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgba(0, 0, 0, 0.63);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.material-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 8px -5px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.single-material-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin: 2px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  cursor: pointer;
}

.single-material-container:hover {
  -webkit-transform: translate(0px, 2px);
  -ms-transform: translate(0px, 2px);
  transform: translate(0px, 2px);
}

.single-material-container.active {
  border: 2px solid #1592e6;
  border-radius: 200px;
}

.material-preview {
  width: 100%;
  border-style: none;
  border-width: 1px;
  border-color: #944f4f;
  border-radius: 250px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09);
}

.material-preview.active {
  box-shadow: 0 0 0 2px #1592e6;
}

.material-name {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 200px;
  background-color: hsla(0, 0%, 100%, 0.6);
  opacity: 0;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #333;
  font-size: 9px;
  line-height: 10px;
  font-weight: 600;
  text-align: center;
}

.material-name.active {
  border: 0px none #000;
}

.backend-photo-preview-empty {
  width: 100%;
  height: 278px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #888;
}

.backend-photo-preview-empty.list {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.backend-photo-preview-empty.small {
  border-radius: 0px;
}

.information-empty {
  margin-right: auto;
  margin-left: auto;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.hamburger-menu {
  width: 19px;
  height: 19px;
  margin-right: 20px;
  margin-left: -8px;
  cursor: pointer;
}

.text-block-47 {
  line-height: 16px;
}

.object-panel-viewer {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 270px;
  margin-top: 15px;
  margin-right: 15px;
  padding: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 7px;
  background-color: #404040;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.38);
  -webkit-transition: height 200ms ease, opacity 200ms ease;
  transition: height 200ms ease, opacity 200ms ease;
  color: #d9d9d9;
}

.object-panel-viewer.small {
  overflow: hidden;
  height: 70px;
}

.object-panel-viewer.out-of-focus {
  opacity: 0.25;
}

.object-panel-viewer.out-of-focus:hover {
  opacity: 1;
}

.object-panel-viewer.out-of-focus.small {
  height: 68px;
}

.object-prev-container {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #a5a5a5;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.27);
}

.objectinformationtextcontainer-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.object-name {
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 10px;
  color: #d9d9d9;
  font-size: 14px;
  line-height: 12px;
}

.object-name-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.object-modifier-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.single-object-modifier-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-weight: 500;
}

.single-object-modifier-container.left {
  height: 27px;
  margin-bottom: 4px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-style: none;
  border-width: 1px;
  border-color: #333;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.single-object-modifier-container.left:hover {
  background-color: #6e6e6e;
}

.text-block-48 {
  color: #d9d9d9;
  font-size: 11px;
  line-height: 12px;
  font-weight: 400;
}

.color-options-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.color-options-container.left {
  margin-left: 1px;
}

.single-color-option {
  width: 22px;
  height: 22px;
  margin-left: 7px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.single-color-option.red {
  background-color: #ff0048;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.single-color-option.red:hover {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
}

.single-color-option.red {
  background-color: #ff0048;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.single-color-option.red:hover {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
}

.single-color-option.red.left {
  position: relative;
  overflow: hidden;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  margin-left: 1px;
  box-shadow: none;
}

.single-color-option.red.left.yellow {
  background-color: #ffe77c;
}

.single-color-option.red.left.blue {
  width: 23px;
  height: 23px;
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  background-color: #78b0d6;
}

.single-color-option-add {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  height: 22px;
  margin-left: 7px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #1592e6;
  border-radius: 100px;
  background-color: rgba(21, 146, 230, 0.12);
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1592e6;
  cursor: pointer;
}

.single-color-option-add:hover {
  background-color: #1592e6;
  color: #fff;
}

.single-color-option-add.red {
  background-color: #ff0048;
}

.text-block-49 {
  color: #707070;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.image-19 {
  padding: 3px;
}

.html-embed-4 {
  width: 15px;
  height: 15px;
  margin-bottom: 2px;
}

.object-controls-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #d9d9d9;
}

.object-controls-container.vertical {
  margin-bottom: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.single-control-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin-right: 10px;
  padding: 7px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.62);
  -webkit-transition: min-width 200ms ease, max-width 200ms ease, width 200ms ease;
  transition: min-width 200ms ease, max-width 200ms ease, width 200ms ease;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.single-control-container:hover {
  width: auto;
  min-width: 65px;
}

.single-control-container.red {
  background-color: #ff0048;
}

.single-control-container.active {
  padding: 5px;
  border: 1px solid #1592e6;
  color: #1592e6;
}

.single-control-container.left-hover {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.single-control-container.end {
  margin-right: 0px;
}

.html-embed-5 {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #d9d9d9;
}

.html-embed-5.delete {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
}

.html-embed-5.back-button {
  height: 9px;
  min-height: 10px;
  margin-top: -8px;
}

.html-embed-5.back-button.forward {
  margin-top: 6px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.text-block-50 {
  min-width: 0px;
  margin-left: 4px;
}

.max-space {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.delete-button-object {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30px;
  margin-right: 0px;
  padding: 7px 9px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ff0048;
  border-radius: 100px;
  background-color: #ff0048;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.34);
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.delete-button-object:hover {
  width: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ff0048;
  background-color: #fff;
  color: #ff0048;
}

.delete-button-object.red {
  background-color: #ff0048;
}

.delete-button-object.active {
  padding: 5px;
  border: 1px solid #1592e6;
  color: #1592e6;
}

.delete-button-object.left-hover {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.delete-button-object.end {
  margin-right: 0px;
}

.delete-button-obj-text {
  min-width: 0px;
  margin-right: 5px;
  margin-left: 7.5px;
  font-weight: 400;
  text-transform: none;
}

.delete-obj-control-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}

.side-menu-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: #2b2b2b;
  color: #2b2b2b;
}

.side-main-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 17px;
  padding-right: 17px;
  padding-left: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sidebar-menu-element {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 70px;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.75;
  color: #255474;
  font-size: 12px;
  font-weight: 600;
}

.sidebar-menu-element.active {
  position: relative;
  width: 70px;
  height: 70px;
  background-color: #f2f5fb;
  opacity: 1;
}

.sidebar-menu-element.active {
  position: relative;
  width: 70px;
  height: 70px;
  background-color: #f2f5fb;
  opacity: 1;
}

.sidebar-menu-icon {
  width: 22px;
  height: 22px;
}

.sidebar-menu-element-text {
  margin-top: 7.5px;
  letter-spacing: -1px;
}

.div-block-42 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #f2f5fb;
}

.div-block-43 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2b2b2b;
  color: hsla(0, 0%, 85.1%, 0.6);
  font-weight: 500;
  cursor: pointer;
}

.div-block-43.active {
  background-color: #f2f5fb;
  color: #1592e6;
}

.div-block-43.active {
  background-color: #404040;
  color: #d9d9d9;
}

.div-block-44 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-shadow: none;
}

.text-field-2 {
  height: 40px;
  margin-bottom: 20px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #2b2b2b;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
  color: #d9d9d9;
  font-size: 11px;
}

.div-block-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -3px;
  margin-left: -3px;
  padding: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.image-20 {
  width: 29.5%;
  margin: 3px;
  border-radius: 3px;
}

.dropdown-toggle {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: none;
}

.dropdown-toggle.w--open {
  display: none;
}

.dropdown {
  position: absolute;
  left: 0%;
  top: -70px;
  right: auto;
  bottom: auto;
}

.dropdown-list {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.dropdown-list.w--open {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.form-block-5 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.form-5 {
  width: 140px;
}

.overlay-container-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75vw;
  max-width: 75vw;
  min-height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px 4px rgba(0, 0, 0, 0.1);
}

.overlay-container-prev.dark-mode {
  background-color: #404040;
  color: #d9d9d9;
}

.creator-overlay-container-preview {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 5vh 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.6);
}

.div-block-46 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70vh;
  max-height: 800px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-47 {
  width: 25%;
  max-height: 800px;
  padding: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.image-21 {
  max-height: 100%;
  border-radius: 5px;
}

.h1-prev {
  margin-bottom: 29px;
  color: #333;
  font-size: 26px;
  line-height: 32px;
}

.h1-prev.dark-mode {
  color: #d9d9d9;
}

.div-block-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
}

.h4-prev {
  color: #333;
}

.h4-prev.dark-mode {
  color: #d9d9d9;
}

.div-block-49 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.delete-button-object-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30px;
  margin-right: 0px;
  padding: 7px 9px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 3px;
  background-color: #5e5e5e;
  -webkit-transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.delete-button-object-2:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #ff6969;
  background-color: #404040;
  color: #ff6969;
}

.div-block-50 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #f2f5fb;
}

.viewer-side-menu-container-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 310px;
  border: 2px none #000;
  background-color: #404040;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.single-color-option-add-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  height: 22px;
  margin-left: 7px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #1592e6;
  border-radius: 100px;
  background-color: rgba(21, 146, 230, 0.12);
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1592e6;
  cursor: pointer;
}

.single-color-option-add-2:hover {
  background-color: #1592e6;
  color: #fff;
}

.single-control-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 30px;
  min-width: 40px;
  margin-right: 0px;
  padding: 7px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 0px;
  background-color: #5e5e5e;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.single-control-container-2:hover {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-color: #2b2b2b;
  background-color: #6e6e6e;
}

.single-control-container-2.active {
  padding: 5px;
  border-style: solid;
  border-color: #333;
  background-color: #2b2b2b;
}

.single-control-container-2.end {
  margin-right: 0px;
}

.single-control-container-2.left {
  padding: 7px;
  border-left-style: solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.single-control-container-2.right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.single-control-container-2.no-grow {
  width: 30px;
  min-width: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.single-control-container-2.no-grow.rounded {
  border-left-style: solid;
  border-radius: 3px;
}

.single-control-container-2.no-grow.rounded.no-margin-left {
  margin-left: 0px;
}

.single-control-container-2.rounded {
  margin-left: 5px;
}

.dropdown-toggle-2 {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.tag-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 14px;
  padding: 9px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 20px;
  background-color: rgba(206, 228, 243, 0.35);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #1592e6;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.tag-container:hover {
  background-color: #cee4f3;
}

.tag-container.dark-mode {
  background-color: #2b2b2b;
  color: #d9d9d9;
}

.div-block-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -14px;
  margin-left: -14px;
}

.h2-prev {
  color: #333;
}

.h2-prev.dark-mode {
  color: #d9d9d9;
}

.div-block-52 {
  width: 75%;
}

.scrollable-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  margin-right: -17px;
  margin-left: -17px;
  padding-right: 17px;
  padding-left: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-block-6 {
  position: relative;
  margin-bottom: 0px;
}

.swatches-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -2px;
  margin-left: -2px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swatches-spacer-container {
  padding-top: 11px;
  padding-bottom: 0px;
  border-top: 1px solid #212121;
}

.div-block-53 {
  background-color: #404040;
}

.single-color-swatch {
  width: 25px;
  height: 25px;
  margin: 2px;
  border: 1px solid #212121;
  border-radius: 2px;
  background-color: #2c3d1d;
  color: #707070;
  cursor: pointer;
}

.text-block-51 {
  width: 62px;
  margin-right: 15px;
  color: #d9d9d9;
  font-size: 11px;
  line-height: 12px;
}

.div-block-54 {
  width: 100%;
  height: 1px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #2b2b2b;
}

.div-block-54.lighter {
  background-color: #404040;
}

.div-block-54.lighter.vertical {
  width: 1px;
  height: 100%;
  min-height: 100%;
  margin: 0px 4px;
  background-color: #2b2b2b;
}

.tag-color {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.svg-inherit {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.svg-inherit.delete {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
}

.svg-inherit.delete.video {
  height: 12px;
  min-height: 17.5px;
}

.svg-inherit.margin-right {
  margin-right: 5px;
}

.side-panel-inner-heading {
  margin-top: 2px;
  margin-bottom: 20px;
  color: #d9d9d9;
  font-size: 14px;
  line-height: 12px;
}

.form-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.remove-search {
  position: absolute;
  left: auto;
  top: 12px;
  right: 12px;
  bottom: auto;
  width: 17px;
  height: 17px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #d9d9d9;
  cursor: pointer;
}

.remove-search.hidden {
  opacity: 0;
}

.help-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #d9d9d9;
}

.div-block-55 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.side-panel-inner-heading-element {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #d9d9d9;
  font-size: 12px;
  line-height: 11px;
}

.side-panel-inner-heading-element.margin-bottom {
  margin-bottom: 10px;
}

.regular-button-ui {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30px;
  margin-right: 0px;
  padding: 7px 9px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 3px;
  background-color: #5e5e5e;
  -webkit-transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.regular-button-ui:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  background-color: #6e6e6e;
  color: #d9d9d9;
}

.regular-button-ui.full-width {
  width: 100%;
  text-decoration: none;
}

.regular-button-ui.margin-left {
  margin-left: 5px;
}

.shortcut-lines {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 3px 3px 3px 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 11px;
  line-height: 11px;
  text-align: left;
}

.ui-key {
  padding: 3px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 2px;
  background-color: #404040;
}

.div-block-56 {
  width: 100%;
  margin-top: 7px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 3px;
  background-color: #5e5e5e;
}

.viewer-top-bar-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 8px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid none;
  border-width: 1px;
  border-color: #2b2b2b;
  background-color: #404040;
  color: #d9d9d9;
}

.viewer-top-menu-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.viewer-top-menu-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30vw;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.top-header {
  opacity: 0.4;
  font-size: 12px;
}

.close-viewer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: transparent;
  opacity: 0.4;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.close-viewer:hover {
  opacity: 1;
}

.close-viewer.minimize {
  padding-top: 1px;
}

.close-viewer-icon {
  width: 15px;
  height: 15px;
  margin-top: -3px;
}

.top-bar-element-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 0px;
  padding: 7px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: #2b2b2b;
  border-radius: 0px;
  background-color: #404040;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.top-bar-element-button:hover {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-color: #2b2b2b;
  background-color: #6e6e6e;
}

.top-bar-element-button.active {
  padding: 5px;
  border-style: solid;
  border-color: #333;
  background-color: #2b2b2b;
}

.top-bar-element-button.end {
  margin-right: 0px;
}

.top-bar-element-button.left {
  padding: 7px;
  border-left-style: solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.top-bar-element-button.right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.top-bar-element-button.no-grow {
  width: 30px;
  min-width: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.top-bar-element-button.no-grow.rounded {
  border-left-style: solid;
  border-radius: 3px;
}

.top-bar-element-button.no-grow.rounded.no-margin-left {
  margin-left: 0px;
}

.top-bar-element-button.rounded {
  margin-left: 5px;
}

.top-bar-element-button.gorw {
  width: auto;
  min-width: auto;
}

.icon-top-bar {
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #d9d9d9;
}

.icon-top-bar.delete {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
}

.icon-top-bar.back-button {
  height: 9px;
  min-height: 10px;
  margin-top: -8px;
}

.icon-top-bar.back-button.forward {
  margin-top: 6px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.top-bar-text {
  min-width: 0px;
  margin-right: 5px;
  margin-left: 7.5px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  text-transform: none;
}

.hamburger-menu-icon {
  width: 20px;
  height: 20px;
}

.body-2.dark-mode {
  background-color: #2b2b2b;
}

.single-material-option {
  position: relative;
  width: 27px;
  height: 27px;
  margin-right: 8px;
  margin-left: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #333;
  border-radius: 20px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.single-material-option.red {
  background-color: #ff0048;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.single-material-option.red:hover {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
}

.single-material-option.red {
  background-color: #ff0048;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.single-material-option.red:hover {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.7);
}

.single-material-option.red.left {
  position: relative;
  overflow: hidden;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  margin-left: 1px;
  box-shadow: none;
}

.single-material-option.red.left.yellow {
  background-color: #ffe77c;
}

.single-material-option.red.left.blue {
  width: 23px;
  height: 23px;
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  background-color: #78b0d6;
}

.material-preview-image {
  border-radius: 100px;
}

.backward-button-container {
  position: fixed;
  left: 1vw;
  top: 40vh;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10vw;
  height: 20vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.28);
  cursor: pointer;
}

.backward-button-container.forward {
  left: auto;
  right: 1vw;
}

.html-embed-6 {
  width: 100%;
}

.joyride-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.69);
}

.div-block-57 {
  width: 50%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
  background-color: #fff;
}

.background-video {
  width: 100%;
  height: 490px;
  background-color: rgba(126, 76, 76, 0.49);
}

.background-video-2 {
  z-index: 6;
  height: 390px;
}

.background-video-2._2 {
  display: none;
}

.side-menu-container-bottom {
  margin-top: auto;
}

.div-block-58 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.backend-add-button {
  margin-left: 40px;
  padding: 12px 22px;
  border-radius: 7.5px;
  background-color: #f1f3fa;
  color: #6f737a;
  font-size: 12px;
  font-weight: 600;
}

.single-product-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
  border-style: solid;
  border-width: 1.5px;
  border-color: transparent;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  cursor: pointer;
}

.single-product-container:hover {
  border-color: rgba(21, 146, 230, 0.6);
}

.single-product-container.active {
  border-color: #1592e6;
  box-shadow: 0 0 0 4px rgba(21, 146, 230, 0.16);
}

.product-preview-image-element {
  width: 150px;
  border-radius: 10px;
}

.product-information-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.product-property-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
}

.product-property-container.text {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-block-52 {
  color: #333;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.text-block-52.center {
  text-align: center;
}

.text-block-53 {
  margin-top: 9px;
  opacity: 0.64;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.div-block-59 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #a5a5b1;
  font-weight: 600;
}

.html-embed-7 {
  width: 20px;
  height: 20px;
}

.div-block-60 {
  margin-left: 15px;
}

.product-colors-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.product-single-color-swatch {
  width: 45px;
  height: 16px;
  margin-right: 7px;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.16);
}

.product-single-color-swatch._1 {
  background-color: #ce1b89;
}

.product-single-color-swatch._2 {
  background-color: #1592e6;
}

.div-block-61 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-54 {
  font-size: 12px;
}

.product-page-backend-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.product-detail-panel {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 340px;
  height: 100vh;
  min-width: 300px;
  padding: 50px 30px 30px;
  box-shadow: 0 0 20px 0 rgba(63, 69, 87, 0.22);
}

.product-preview-detail-view {
  width: 100%;
  margin-top: 45px;
  border-radius: 10px;
}

.product-colors-container-detail-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
  margin-right: -7px;
  margin-left: -7px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-single-color-swatch-detail-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 133px;
  height: 40px;
  margin-right: 7px;
  margin-bottom: 14px;
  margin-left: 7px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.product-single-color-swatch-detail-view._1 {
  background-color: #ce1b89;
}

.product-single-color-swatch-detail-view._2 {
  background-color: #1592e6;
}

.new-color-detail-view-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 133px;
  height: 40px;
  margin-right: 7px;
  margin-bottom: 14px;
  margin-left: 7px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  background-color: #f1f3fa;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.new-color-detail-view-button:hover {
  background-color: #e5e8f3;
}

.new-color-detail-view-button._1 {
  background-color: #ce1b89;
}

.new-color-detail-view-button._2 {
  background-color: #1592e6;
}

.text-block-55 {
  color: #6f737a;
}

.hint {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 43.9%, 0.5);
  border-radius: 7px;
  background-color: transparent;
  font-size: 12px;
  font-style: italic;
}

.accept-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30px;
  margin-right: 0px;
  padding: 7px 9px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #44b276;
  border-radius: 3px;
  background-color: #5e5e5e;
  -webkit-transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: opacity 200ms ease, background-color 200ms ease, border-color 200ms ease;
  color: #44b276;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.accept-button:hover {
  border-style: solid;
  border-width: 1px;
  background-color: rgba(68, 178, 118, 0.14);
}

.placeholder-max {
  height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.delte-color-swatch {
  width: 30px;
  opacity: 0.55;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #fff;
  cursor: pointer;
}

.delte-color-swatch:hover {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .menu-image-link-container {
    width: 100%;
    margin-top: 20px;
    margin-right: 0px;
    font-size: 16px;
  }
  .form-block-2 {
    max-width: 100vw;
  }
  .header-menu-sign-in {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 15px 0px 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .menu-right-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .menu-center-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .div-block {
    width: 100%;
    margin-top: 17px;
    margin-bottom: 17px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .sign-up-container {
    margin-left: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .navbar-2 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .nav-menu {
    padding-right: 25px;
    padding-left: 25px;
    background-color: #fff;
  }
  .menu-button {
    margin-left: auto;
  }
  .creator-overlay-container {
    padding: 45px 30px;
  }
  .backend-photo-overlay-container {
    padding: 45px 30px;
  }
  .cta3-item-wrap.cta3-recommended-option {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  ._5-col {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .text-16.text-16-60 {
    width: 100%;
  }
  .decoline {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .decoline.decoline-dark.left-align-decoline-dark {
    margin-right: 0px;
    margin-left: 0px;
  }
  .cta3-options-wrapper {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  ._2-col {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .home-category-linkitem {
    margin-bottom: 0px;
  }
  ._4-col {
    display: inline-block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
  ._4-col._4-col-last {
    margin-right: auto;
    margin-bottom: 0px;
  }
  .creator-overlay-container-preview {
    padding: 45px 30px;
  }
}

@media screen and (max-width: 767px) {
  .home-section {
    padding-right: 33px;
    padding-left: 33px;
  }
  .div-block-2 {
    width: 100%;
  }
  .form-3 {
    width: 100%;
  }
  .div-block-18 {
    max-width: 330px;
  }
  .container-flex-n1 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 479px) {
  .header-menu-sign-in {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .login-text-field {
    font-size: 12px;
  }
  .div-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .text-block-7 {
    font-size: 11px;
  }
  .heading-3 {
    font-size: 18px;
  }
  .regular-button.full-width.bold {
    font-size: 13px;
  }
  .div-block-18 {
    width: 330px;
    max-width: 95vw;
  }
  .forgot {
    font-size: 10px;
  }
  .btn {
    display: block;
    text-align: center;
  }
  .btn-big {
    display: block;
    padding: 14px 25px;
    text-align: center;
  }
}
