.dark-mode .menu-image-link-container {
  color: #d9d9d9;
}

.dark-mode .login-text-field.menu-search {
  border-style: solid;
  border-color: #333;
  background-color: #2b2b2b;
  box-shadow: 0 1px 6px -9px rgba(0, 0, 0, 0.49);
  color: #d9d9d9;
}

.dark-mode .menu-logo {
  width: 70px;
  margin-top: 6px;
  color: #d9d9d9;
}

.dark-mode .text-field {
  padding-left: 21px;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 100px;
  background-color: #2b2b2b;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.57);
  font-size: 14px;
}

.dark-mode .navbar-2 {
  border-bottom: 1px solid #333;
  background-color: #404040;
  color: #d9d9d9;
}

.dark-mode .categories-header {
  background-color: #404040;
  color: #d9d9d9;
}

.dark-mode .categorie-link-container {
  opacity: 0.5;
  -webkit-transition: opacity 200ms ease, color 200ms ease;
  transition: opacity 200ms ease, color 200ms ease;
  color: #d9d9d9;
  font-weight: 500;
}

.dark-mode .categorie-link-container:hover {
  opacity: 0.7;
}

.dark-mode .categorie-link-container.active {
  border-bottom-color: #d9d9d9;
  opacity: 1;
}

.dark-mode .text-block-32 {
  opacity: 0.8;
  color: #d9d9d9;
}

.dark-mode .vertical-spacer {
  background-color: #d9d9d9;
}

.dark-mode .div-block-26 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#404040));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #404040);
}

.dark-mode .viewerclosed {
  border-style: solid solid none;
  border-width: 1px;
  border-color: #333;
  background-color: #404040;
  color: #d9d9d9;
}

.body-2.dark-mode {
  background-color: #2b2b2b;
}
