.circle-overlay-text{
  font-color: white;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.circle-overlay-text .inverted {
  color: #000;
}
