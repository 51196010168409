.scroll-horiztonal-categories{
  height: calc(100% + 15px);
  box-sizing: content-box;
  overflow-x: scroll;
  overflow-y: hidden;
}

.categorie-link-container {
  white-space: nowrap;
}
